@use '@angular/material' as mat;
@use "sass:map";

// https://github.com/angular/components/blob/main/guides/duplicate-theming-styles.md
// fix it later
mat.$theme-ignore-duplication-warnings: true; 

// @import url('https://fonts.googleapis.com/css2?family=Open+Sans&family=Oswald&family=Roboto&display=swap');
// @import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// @import "@angular/material/prebuilt-themes/indigo-pink.css";
// @import "../node_modules/@angular/material/legacy-prebuilt-themes/legacy-deeppurple-amber.css";


@use "./app/components/view-birthdays/view-birthdays.component.theme.scss" as custom1;
@use "./app/components/view-service-commitee/view-service-commitee.component.theme.scss" as custom2;
@use "./app/components/view-graphic-vacation/view-graphic-vacation.component.theme.scss" as custom3;

@mixin custom-component-themes($theme) {
  @include custom1.view-birthday-component-theme($theme);
  @include custom2.view-service-commitee-component-theme($theme);
  @include custom3.view-graphic-vacation-component-theme($theme);
}

@include mat.elevation-classes();
@include mat.app-background();



// Define primary Station Palette
$station-primary: (
  50: #f3f6e8,
  100: #e1e8c5,
  200: #ced99e,
  300: #baca77,
  400: #abbe59,
  500: #9cb33c,
  600: #94ac36,
  700: #8aa32e,
  800: #809a27,
  900: #6e8b1a,
  a100: #efffc3,
  a200: #e1ff90,
  a400: #d3ff5d,
  a700: #ccff43,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #fff,
    a100: #000,
    a200: #000,
    a400: #000,
    a700: #000,
  ),
);

$my-primary: mat.m2-define-palette($station-primary);
$my-accent: mat.m2-define-palette(mat.$m2-amber-palette, 900);
$my-warn: mat.m2-define-palette(mat.$m2-red-palette);

.light-theme {
  $my-theme: mat.m2-define-light-theme((
    color: (
      primary: $my-primary,
      accent: $my-accent,
      warn: $my-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  ));

  @include mat.all-component-themes($my-theme);
  @include custom-component-themes($my-theme);

  a {
    // text-decoration: none;
    color: map.get($my-accent, 800);
    &:visited {
      color: map.get($my-accent, 600);
      // flex-wrap: nowrap;
    }
  }
}

.dark-theme {
  $dark-theme: mat.m2-define-dark-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    warn: $my-warn,
  ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  ));

  // $dark-theme: mat-dark-theme($dark-primary, $dark-accent, $dark-warn);

  @include mat.all-component-themes($dark-theme);
  @include custom-component-themes($dark-theme);


  a {
    // text-decoration: none;
    color: map.get($my-primary, 600);
    &:visited {
      color: map.get($my-primary, 100);
      // flex-wrap: nowrap;
    }
  }

}


// //.bg_odd_even
// .bg_odd_even .mdc-list-item:nth-child(2n+1)
// {
//   background: #f0f0f0; //map.get($my-primary, 500) !important;
// }

// .dark-theme .bg_odd_even .mdc-list-item:nth-child(2n+1)
// {
//   background: #222; //map.get($my-primary, 500) !important;
//   // background: map.get($my-primary, 500) !important;
// }


.no-connection-bar {
  position: absolute;
  padding-top: 40vh;
  height: 60vh;  // 100 with 40 padding
  width: 100%;
  top: 0;
  left: 0;
  text-align: center;
  font-size: 22px;
  background-color: black;
  opacity: 0.8;
  z-index: 10999;
  color: white;
}

.fixed-on-top {
  position: relative;
  top: 0;
  z-index: 10002;

  .loading-bar {
    position: fixed;
    height: 4px;
  }
}


.mat-bg-primary
{
  background: map.get($my-primary, 500) !important;
  color: #ffffff !important;
}
.mat-bg-accent
{
  background-color: map.get($my-accent, 500);
  color: white;
}
.mat-bg-warn {
  background-color: #f44336;
  color: white;
}
.mat-color-accent {
  color: map.get($my-accent, 500);
}
.mat-color-warn {
  color: #f44336;
}
.mat-color-default {
  color: rgba(0, 0, 0, 0.87);
}



// .list-item-active {
//   color: mat-color($my-accent) !important;
// }

// .account-box-debug {
//   color: mat-color($my-warn) !important;
// }

// a {
//   text-decoration: none;
//   // color: mat-color($my-accent);

//   &:hover {
//     text-decoration: underline;
//   }

//   &.auth-link {
//     color: black;
//     flex-wrap: nowrap;
//   }
// }

// .auth-link-bar {
//   display: flex;
//   justify-content: space-evenly;
//   flex-flow: row wrap;
// }

// .mat-button-base {
//   text-transform: uppercase;
// }



body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  margin: 0;
  // padding: 30px;
}

html, body {
  height: 100%;
}


.view-loader {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  // z-index: 9999;
}

.spinner {
  width: 40px;
  height: 40px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  // margin-top: -20px;
  // margin-left: -20px;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  animation-delay: -1s;
}

@keyframes sk-bounce {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.sidenav-container {
  height: 100%;
}

.sidenav {
  // width: 14rem;
  width: 280px;
}

.sidenav .mat-toolbar {
  background: inherit;
}

.mat-toolbar .mat-primary {
  position: sticky;
  top: 0;
  z-index: 1000;
}

.router-wrapper {
  height: 100%;
  width: 100%;
  min-width: 100%;
  min-height: 60%;
}
